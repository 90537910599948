import {lazy} from 'react';
import RoleNames from './lib/RoleNames.ts';
import PageLayout from './components/layout/PageLayout.ts';

const AdminPage = lazy(() => import('./components/admin/AdminPage.tsx'));
const AppsPage = lazy(() => import('./components/admin/AppsPage.tsx'));
const BinariesPage = lazy(() => import('./components/admin/BinariesPage.tsx'));
const CuratedCollectionsPage = lazy(
    () => import('./components/admin/CuratedCollectionsPage.tsx')
);
const DemoSettingsPage = lazy(
    () => import('./components/admin/DemoSettingsPage.tsx')
);
const LogsPage = lazy(() => import('./components/admin/LogsPage.tsx'));
const TransferProjectPage = lazy(
    () => import('./components/admin/TransferProjectPage.tsx')
);
const UserPage = lazy(() => import('./components/admin/UserPage.tsx'));
const UsersPage = lazy(() => import('./components/admin/UsersPage.tsx'));
const AssetsPage = lazy(() => import('./components/assets/AssetsPage.tsx'));
const LoginCallbackPage = lazy(
    () => import('./components/auth/LoginCallbackPage.tsx')
);
const LoginPage = lazy(() => import('./components/auth/LoginPage.tsx'));
const MicrosoftLoginCallbackPage = lazy(
    () => import('./components/auth/MicrosoftLoginCallbackPage.tsx')
);
const MicrosoftRedirectPage = lazy(
    () => import('./components/auth/MicrosoftLoginRedirectPage.tsx')
);
const RegistrationPage = lazy(
    () => import('./components/auth/RegistrationPage.tsx')
);
const ApiDocumentPage = lazy(
    () => import('./components/content_admin/ApiDocumentPage.tsx')
);
const ApiDocumentsPage = lazy(
    () => import('./components/content_admin/ApiDocumentsPage.tsx')
);
const SiteContentEditPage = lazy(
    () => import('./components/content_admin/SiteContentEditPage.tsx')
);
const SiteContentsPage = lazy(
    () => import('./components/content_admin/SiteContentsPage.tsx')
);
const SiteContentTOCPage = lazy(
    () => import('./components/content_admin/SiteContentTOCPage.tsx')
);
const TemplatesPage = lazy(
    () => import('./components/content_admin/TemplatesPage.tsx')
);
const PromptsPage = lazy(
    () => import('./components/content_admin/PromptsPage.tsx')
);
const EditPromptPage = lazy(
    () => import('./components/content_admin/EditPromptPage.tsx')
);
const MixiplyAppsPage = lazy(
    () => import('./components/docs/MixiplyAppsPage.tsx')
);
const DocsHomePage = lazy(() => import('./components/docs/DocsHomePage.tsx'));
const MyGroupMembersPage = lazy(
    () => import('./components/group_members/MyGroupMembersPage.tsx')
);
const CreateGroupPage = lazy(
    () => import('./components/groups/CreateGroupPage.tsx')
);
const GroupPage = lazy(() => import('./components/groups/GroupPage.tsx'));
const GroupsPage = lazy(() => import('./components/groups/GroupsPage.tsx'));
const HomePage = lazy(() => import('./components/home/HomePage.tsx'));
const TermsOfServicePage = lazy(
    () => import('./components/home/TermsOfServicePage.tsx')
);
const ProfilePage = lazy(() => import('./components/profile/ProfilePage.tsx'));
const ExploreProjectsPage = lazy(
    () => import('./components/projects/ExploreProjectsPage.tsx')
);
const MyProjectsPage = lazy(
    () => import('./components/projects/MyProjectsPage.tsx')
);
const NewProjectPage = lazy(
    () => import('./components/projects/NewProjectPage.tsx')
);
const ProjectContentFullscreenPage = lazy(
    () => import('./components/projects/ProjectContentFullscreenPage.tsx')
);
const ProjectPage = lazy(() => import('./components/projects/ProjectPage.tsx'));
const ProjectTemplateArgsPage = lazy(
    () => import('./components/projects/ProjectTemplateArgsPage.tsx')
);
const SiteContentPage = lazy(
    () => import('./components/site_contents/SiteContentPage.tsx')
);
const AllComponentsPage = lazy(
    () => import('./components/home/AllComponentsPage.tsx')
);
const NotFoundPage = lazy(() => import('./components/home/NotFoundPage.tsx'));

export interface Page {
    paths: string[];
    component: React.LazyExoticComponent<React.FC>;
    authorize?: boolean;
    roles?: string[];
    pageLayout?: PageLayout;
    translation?: boolean;
}

interface Pages {
    [key: string]: Page;
}

const pages: Pages = {
    AdminPage: {
        paths: ['/admin'],
        component: AdminPage,
        authorize: true,
        roles: [RoleNames.Admin, RoleNames.ContentEditor],
        pageLayout: PageLayout.Standard
    },
    AppsPage: {
        paths: ['/apps'],
        component: AppsPage,
        authorize: true,
        roles: [RoleNames.Admin],
        pageLayout: PageLayout.Standard
    },
    BinariesPage: {
        paths: ['/binaries'],
        component: BinariesPage,
        authorize: true,
        roles: [RoleNames.Admin],
        pageLayout: PageLayout.Standard
    },
    CuratedCollectionsPage: {
        paths: ['/curatedcollections'],
        component: CuratedCollectionsPage,
        authorize: true,
        roles: [RoleNames.Admin],
        pageLayout: PageLayout.Standard
    },
    DemoSettingsPage: {
        paths: ['/demosettings'],
        component: DemoSettingsPage,
        authorize: true,
        roles: [RoleNames.Admin],
        pageLayout: PageLayout.Standard
    },
    LogsPage: {
        paths: ['/log-records'],
        component: LogsPage,
        authorize: true,
        roles: [RoleNames.Admin],
        pageLayout: PageLayout.Standard
    },
    TransferProjectPage: {
        paths: ['/transferproject'],
        component: TransferProjectPage,
        authorize: true,
        roles: [RoleNames.Admin],
        pageLayout: PageLayout.Standard
    },
    UserPage: {
        paths: ['/user/:id'],
        component: UserPage,
        authorize: true,
        roles: [RoleNames.Admin],
        pageLayout: PageLayout.Standard
    },
    UsersPage: {
        paths: ['/users'],
        component: UsersPage,
        authorize: true,
        roles: [RoleNames.Admin],
        pageLayout: PageLayout.Standard
    },
    AssetsPage: {
        paths: ['/assets'],
        component: AssetsPage,
        authorize: true,
        roles: [RoleNames.User, RoleNames.Admin],
        pageLayout: PageLayout.Standard
    },
    LoginCallbackPage: {
        paths: ['/login_callback'],
        component: LoginCallbackPage,
        pageLayout: PageLayout.Standard
    },
    LoginPage: {
        paths: ['/login'],
        component: LoginPage,
        pageLayout: PageLayout.Standard
    },
    MicrosoftLoginCallbackPage: {
        paths: ['/ms_callback'],
        component: MicrosoftLoginCallbackPage,
        pageLayout: PageLayout.None
    },
    MicrosoftRedirectPage: {
        paths: ['/ms_redirect'],
        component: MicrosoftRedirectPage,
        pageLayout: PageLayout.None
    },
    RegistrationPage: {
        paths: ['/registration'],
        component: RegistrationPage,
        pageLayout: PageLayout.Standard
    },
    ApiDocumentPage: {
        paths: ['/apidocument/:id'],
        component: ApiDocumentPage,
        authorize: true,
        roles: [RoleNames.Admin, RoleNames.ContentEditor],
        pageLayout: PageLayout.Standard
    },
    ApiDocumentsPage: {
        paths: ['/apidocuments'],
        component: ApiDocumentsPage,
        authorize: true,
        roles: [RoleNames.Admin, RoleNames.ContentEditor],
        pageLayout: PageLayout.Standard
    },
    SiteContentEditPage: {
        paths: ['/sitecontent/:id'],
        component: SiteContentEditPage,
        authorize: true,
        roles: [RoleNames.Admin, RoleNames.ContentEditor],
        pageLayout: PageLayout.Standard
    },
    SiteContentsPage: {
        paths: ['/sitecontents'],
        component: SiteContentsPage,
        authorize: true,
        roles: [RoleNames.Admin, RoleNames.ContentEditor],
        pageLayout: PageLayout.Standard
    },
    SiteContentTOCPage: {
        paths: ['/sitecontent/toc'],
        component: SiteContentTOCPage,
        authorize: true,
        roles: [RoleNames.Admin, RoleNames.ContentEditor],
        pageLayout: PageLayout.Standard
    },
    TemplatesPage: {
        paths: ['/templates'],
        component: TemplatesPage,
        authorize: true,
        roles: [RoleNames.ContentEditor, RoleNames.Admin],
        pageLayout: PageLayout.Standard
    },
    PromptsPage: {
        paths: ['/prompts'],
        component: PromptsPage,
        authorize: true,
        roles: [RoleNames.ContentEditor, RoleNames.Admin],
        pageLayout: PageLayout.Standard
    },
    EditPromptPage: {
        paths: ['/prompts/edit/:name'],
        component: EditPromptPage,
        authorize: true,
        roles: [RoleNames.ContentEditor, RoleNames.Admin],
        pageLayout: PageLayout.Standard
    },
    MixiplyAppsPage: {
        paths: ['/mixiply-apps'],
        component: MixiplyAppsPage,
        pageLayout: PageLayout.Standard
    },
    DocsHomePage: {
        paths: ['/docs-home'],
        component: DocsHomePage,
        pageLayout: PageLayout.Standard
    },
    MyGroupMembersPage: {
        paths: ['/mygroupmembers'],
        component: MyGroupMembersPage,
        pageLayout: PageLayout.Standard
    },
    CreateGroupPage: {
        paths: ['/create-group'],
        component: CreateGroupPage,
        pageLayout: PageLayout.Standard
    },
    GroupPage: {
        paths: ['/explore/groups/:containerName'],
        component: GroupPage,
        pageLayout: PageLayout.Group
    },
    GroupsPage: {
        paths: ['/explore/groups'],
        component: GroupsPage,
        pageLayout: PageLayout.Standard
    },
    HomePage: {
        paths: ['/'],
        component: HomePage,
        pageLayout: PageLayout.Standard
    },
    TermsOfServicePage: {
        paths: ['/termsofservice', '/app/termsofservice'],
        component: TermsOfServicePage,
        pageLayout: PageLayout.Standard
    },
    ProfilePage: {
        paths: ['/profile'],
        component: ProfilePage,
        pageLayout: PageLayout.Standard
    },
    ExploreProjectsPage: {
        paths: ['/explore'],
        component: ExploreProjectsPage,
        pageLayout: PageLayout.Standard
    },
    MyProjectsPage: {
        paths: ['/myprojects'],
        component: MyProjectsPage,
        pageLayout: PageLayout.Standard
    },
    NewProjectPage: {
        paths: ['/new-project'],
        component: NewProjectPage,
        pageLayout: PageLayout.Standard
    },
    ProjectContentFullscreenPage: {
        paths: ['/project/content/:id'],
        component: ProjectContentFullscreenPage,
        pageLayout: PageLayout.FullScreen
    },
    ProjectPage: {
        paths: ['/project/:id'],
        component: ProjectPage,
        pageLayout: PageLayout.Project,
        translation: true
    },
    ProjectTemplateArgsPage: {
        paths: ['/new/:id'],
        component: ProjectTemplateArgsPage,
        pageLayout: PageLayout.Standard,
        translation: true
    },
    SiteContentPage: {
        paths: ['/content', '/content/:slug'],
        component: SiteContentPage,
        pageLayout: PageLayout.Standard,
        translation: true
    },
    AllComponentsPage: {
        paths: ['/allcomponents'],
        component: AllComponentsPage,
        pageLayout: PageLayout.Standard
    },
    NotFoundPage: {
        paths: ['*'],
        component: NotFoundPage,
        pageLayout: PageLayout.Standard
    }
};

export default pages;
