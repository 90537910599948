import ProjectTabCanvas from './ProjectTabCanvas.tsx';
import ProjectTabScene from './ProjectTabScene.tsx';
import ProjectTabWebsite from './ProjectTabWebsite.tsx';
import ProjectTabContent from './ProjectTabContent.tsx';
import {
    MixModel,
    ProjectDataIndex,
    ProjectTab,
    ProjectTabType,
    TemplateContentConfig
} from '../../../api/types.ts';

interface ProjectTabPaneProps {
    mix: MixModel;
    saveProject?: (project: MixModel) => Promise<void>;
    tab: ProjectTab;
    dataIndex: ProjectDataIndex;
    readOnly: boolean;
    reactionsEnabled: boolean;
    templateConfig?: TemplateContentConfig | null;
}

const ProjectTabPane: React.FC<ProjectTabPaneProps> = ({
    mix,
    saveProject,
    tab,
    dataIndex,
    readOnly,
    reactionsEnabled,
    templateConfig
}) => {
    switch (tab.type) {
        case ProjectTabType.Canvas:
            return (
                <ProjectTabCanvas
                    mix={mix}
                    tab={tab}
                    dataIndex={dataIndex}
                    readOnly={readOnly}
                    reactionsEnabled={reactionsEnabled}
                />
            );
        case ProjectTabType.Scene:
            return (
                <ProjectTabScene
                    mix={mix}
                    tab={tab}
                    dataIndex={dataIndex}
                    readOnly={readOnly}
                />
            );
        case ProjectTabType.Website:
            return (
                <ProjectTabWebsite
                    mix={mix}
                    saveProject={saveProject}
                    tab={tab}
                    dataIndex={dataIndex}
                    readOnly={readOnly}
                />
            );
        default:
            return (
                <ProjectTabContent
                    mix={mix}
                    tab={tab}
                    dataIndex={dataIndex}
                    readOnly={readOnly}
                    templateConfig={templateConfig}
                />
            );
    }
};

export default ProjectTabPane;
