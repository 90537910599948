import React, {useEffect, useRef, useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import MxNotificationCard from './MxNotificationCard';
import {useAuthStateManager} from '../../hooks/useAuthStateManager.tsx';
import {NotificationModel, Period} from '../../api/types.ts';
import useGetNotifications, {
    GetNotificationsQuery
} from '../../api/notifications/useGetNotifications.ts';
import useUnreadNotificationsCount from '../../api/notifications/useUnreadNotificationsCount.ts';
import useMarkNotificationsAsRead from '../../api/notifications/useMarkNotificationsAsRead.ts';
import {useEffectOnce} from '../../hooks/useEffectOnce.ts';

const MxNotifications: React.FC = () => {
    const {loggedInUserId} = useAuthStateManager();
    const [notifications, setNotifications] = useState<NotificationModel[]>([]);
    const notificationsRef = useRef<NotificationModel[]>([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        notificationsRef.current = notifications;
    }, [notifications]);

    const fetchUnreadNotificationsCount = useUnreadNotificationsCount({});
    const {
        data: fetchedUnreadNotificationsCount,
        refetch: refetchUnreadNotificationsCount
    } = useQuery(
        ['unreadNotificationsCount', loggedInUserId],
        fetchUnreadNotificationsCount
    );

    const unreadNotificationsCount = fetchedUnreadNotificationsCount ?? 0;

    const [queryParams, setQueryParams] = useState<GetNotificationsQuery>({
        dateTimeFromUtc: null,
        period: Period.AllTime,
        take: 20
    });

    const getNotifications = useGetNotifications(queryParams);

    const addNotifications = (newNotifications: NotificationModel[]) => {
        setNotifications(prevNotifications => {
            const uniqueNotifications = [
                ...prevNotifications,
                ...newNotifications
            ].filter(
                (post, index, self) =>
                    index === self.findIndex(p => p.id === post.id)
            );
            uniqueNotifications.sort((a, b) =>
                b.createdOnUtc.localeCompare(a.createdOnUtc)
            );
            return uniqueNotifications;
        });
    };

    const fetchNotifications = async () => {
        try {
            const notifications = await getNotifications();
            notifications && addNotifications(notifications);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    useEffect(() => {
        fetchNotifications();
        refetchUnreadNotificationsCount();
    }, [queryParams]);

    const markNotificationsAsRead = useMarkNotificationsAsRead();
    const markNotificationsAsReadMutation = useMutation(
        markNotificationsAsRead
    );

    const handleMarkNotificationsAsRead = () => {
        markNotificationsAsReadMutation.mutate({});
    };

    const onNewNotifications = () => {
        if (fetchedUnreadNotificationsCount === 0) {
            setNotifications(prevState => [
                ...prevState.map(n => ({...n, viewed: true}))
            ]);
        }

        const dateTimeFromUtc: string | null =
            notificationsRef.current.slice(-1)[0]?.createdOnUtc ?? null;

        setQueryParams(prevState => ({
            ...prevState,
            dateTimeFromUtc: dateTimeFromUtc,
            period: Period.Newer
        }));
    };

    useEffectOnce(() => {
        const handleNewNotifications = () => {
            onNewNotifications();
        };

        window.addEventListener('NewNotifications', handleNewNotifications);

        return () => {
            window.removeEventListener(
                'NewNotifications',
                handleNewNotifications
            );
        };
    });

    const toggleDropdown = () => {
        const isOpening = !dropdownOpen;

        if (isOpening) {
            markNotificationsAsReadMutation.mutate({});
        } else {
            setNotifications(prevState => [
                ...prevState.map(n => ({...n, viewed: true}))
            ]);
        }

        setDropdownOpen(isOpening);
    };

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle
                color='light'
                title="Notifcations"
                id="notifDropdown"
                onClick={handleMarkNotificationsAsRead}
            >
                <img src="/images/icons/bell-solid.svg" alt="Notification" />
                {unreadNotificationsCount > 0 && (
                    <div className="notification-indicator d-flex align-items-center justify-content-center">
                        <span className="notification-counter">
                            {unreadNotificationsCount > 9
                                ? '9+'
                                : unreadNotificationsCount}
                        </span>
                    </div>
                )}
            </DropdownToggle>
            <DropdownMenu className="notifications-dropdown">
                {notifications && (
                    <div className="notifications-content custom-scrollbar">
                        <h5>Notifications</h5>
                        {notifications.some(n => !n.viewed) && <h6>New</h6>}
                        {notifications
                            .filter(n => !n.viewed)
                            .map(notification => (
                                <MxNotificationCard
                                    key={notification.id}
                                    notification={notification}
                                />
                            ))}
                        {notifications.some(n => n.viewed) && (
                            <h6 className="mt-4">Older</h6>
                        )}
                        {notifications
                            .filter(n => n.viewed)
                            .map(notification => (
                                <MxNotificationCard
                                    key={notification.id}
                                    notification={notification}
                                />
                            ))}
                    </div>
                )}
            </DropdownMenu>
        </Dropdown>
    );
};

export default MxNotifications;
