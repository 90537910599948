import {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faSignInAlt,
    faSignOutAlt,
    faHome,
    faHeart,
    faPaintBrush,
    faUserFriends,
    faCloud,
    faLightbulb,
    faUserShield,
    faUser
} from '@fortawesome/free-solid-svg-icons';
import {useAuthStateManager} from '../../hooks/useAuthStateManager.tsx';
import RoleNames from '../../lib/RoleNames.ts';
import SiteContentConstants from '../../lib/SiteContentConstants.ts';
import atify from '../../lib/atify.ts';
import {Link, useNavigate} from 'react-router-dom';
import {UserStatus} from '../../api/types.ts';
import {ReactStateSetter} from '../../types.ts';

type LinkMatch = {path: string; startsWith?: boolean};

type MenuProps = {
    isOpen: boolean;
    setIsOpen: ReactStateSetter<boolean>;
};

const Menu = ({isOpen, setIsOpen}: MenuProps) => {
    const navigate = useNavigate();
    const {authData, logout} = useAuthStateManager();
    const [mustCompleteRegistration, setMustCompleteRegistration] =
        useState(false);

    useEffect(() => {
        const userStatus = authData.user_status;
        setMustCompleteRegistration(
            authData.isAuthenticated && userStatus === UserStatus.NotRegistered
        );
    }, [authData]);

    const logoutAsync = async (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setIsOpen(false);
        logout();
        navigate('/');
    };

    const userNickname = () => {
        const nickname = authData.nickname;

        if (nickname) {
            return atify(nickname);
        }

        return 'Anonymous user';
    };

    const matchesPage = (linkMatch: LinkMatch) => {
        const baseRelativePath = window.location.pathname
            .substring(1)
            .toLowerCase();

        return linkMatch.startsWith
            ? baseRelativePath.startsWith(linkMatch.path.toLowerCase())
            : baseRelativePath == linkMatch.path.toLowerCase();
    };

    const navLinkCssClass = (linkMatches: LinkMatch[]) => {
        return linkMatches.some(matchesPage) ? 'nav-link active' : 'nav-link';
    };

    return (
        <ul className={`topnav-menu ${isOpen ? 'is-open' : ''} d-flex`}>
            {mustCompleteRegistration ? (
                <>
                    <li>
                        <Link
                            onClick={() => setIsOpen(false)}
                            className={`nav-item ${navLinkCssClass([
                                {path: 'registration'}
                            ])}`}
                            to="/registration"
                            title="Register"
                        >
                            <FontAwesomeIcon icon={faSignInAlt} />
                            <span className="nav-link-text">Register</span>
                        </Link>
                    </li>
                    <li className="ms-auto">
                        <a
                            href="#"
                            className="nav-item nav-link"
                            onClick={logoutAsync}
                            title="Logout"
                        >
                            <FontAwesomeIcon icon={faSignOutAlt} />
                            <span className="nav-link-text">Logout</span>
                        </a>
                    </li>
                </>
            ) : (
                <>
                    <li>
                        <Link
                            onClick={() => setIsOpen(false)}
                            className={`nav-item ${navLinkCssClass([
                                {path: ''}
                            ])}`}
                            to="/"
                            title="Home"
                        >
                            <FontAwesomeIcon icon={faHome} size="lg" />
                            <span className="nav-link-text">Home</span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => setIsOpen(false)}
                            className={`nav-item ${navLinkCssClass([
                                {path: 'explore'},
                                {path: 'project/', startsWith: true}
                            ])}`}
                            to="/explore"
                            title="Projects"
                        >
                            <FontAwesomeIcon icon={faHeart} size="lg" />
                            <span className="nav-link-text">Projects</span>
                        </Link>
                    </li>
                    {authData.isAuthenticated && (
                        <li>
                            <Link
                                onClick={() => setIsOpen(false)}
                                className={`nav-item ${navLinkCssClass([
                                    {path: 'myprojects'}
                                ])}`}
                                to="/myprojects"
                                title="My projects"
                            >
                                <FontAwesomeIcon
                                    icon={faPaintBrush}
                                    size="lg"
                                />
                                <span className="nav-link-text">
                                    My projects
                                </span>
                            </Link>
                        </li>
                    )}
                    <li>
                        <Link
                            onClick={() => setIsOpen(false)}
                            className={`nav-item ${navLinkCssClass([
                                {path: 'explore/groups'},
                                {path: 'explore/groups/', startsWith: true}
                            ])}`}
                            to="/explore/groups"
                            title="Groups"
                        >
                            <FontAwesomeIcon icon={faUserFriends} size="lg" />
                            <span className="nav-link-text">Groups</span>
                        </Link>
                    </li>
                    {authData.isAuthenticated && (
                        <li>
                            <Link
                                onClick={() => setIsOpen(false)}
                                className={`nav-item ${navLinkCssClass([
                                    {path: 'assets'}
                                ])}`}
                                to="/assets"
                                title="Assets"
                            >
                                <FontAwesomeIcon icon={faCloud} size="lg" />
                                <span className="nav-link-text">Assets</span>
                            </Link>
                        </li>
                    )}
                    <li>
                        <Link
                            onClick={() => setIsOpen(false)}
                            className={`nav-item ${navLinkCssClass([
                                {path: 'content', startsWith: true}
                            ])}`}
                            to="/content"
                            title={SiteContentConstants.ContentIndexPageTitle}
                        >
                            <FontAwesomeIcon icon={faLightbulb} size="lg" />
                            <span className="nav-link-text">
                                {SiteContentConstants.ContentIndexPageTitle}
                            </span>
                        </Link>
                    </li>
                    {(authData.roles.includes(RoleNames.ContentEditor) ||
                        authData.roles.includes(RoleNames.Admin)) && (
                        <li>
                            <Link
                                onClick={() => setIsOpen(false)}
                                className={`nav-item ${navLinkCssClass([
                                    {path: 'admin'},
                                    {path: 'curatedcollections'},
                                    {path: 'demosettings'},
                                    {path: 'log-records'},
                                    {path: 'transferproject'},
                                    {path: 'users'},
                                    {path: 'user/', startsWith: true},
                                    {path: 'apidocuments'},
                                    {path: 'apidocument/', startsWith: true},
                                    {path: 'templates'},
                                    {path: 'template/', startsWith: true}
                                ])}`}
                                to="/admin"
                                title="Admin"
                            >
                                <FontAwesomeIcon
                                    icon={faUserShield}
                                    size="lg"
                                />
                                <span className="nav-link-text">Admin</span>
                            </Link>
                        </li>
                    )}
                    {authData.isAuthenticated ? (
                        <li className="topnav-user-menu">
                            <Link
                                onClick={() => setIsOpen(false)}
                                className={`nav-item ${navLinkCssClass([
                                    {path: 'profile'}
                                ])}`}
                                to="/profile"
                                title="Profile"
                            >
                                <FontAwesomeIcon icon={faUser} size="lg" />
                                <span className="nav-link-text fw-08">
                                    {userNickname()}
                                </span>
                            </Link>
                            <a
                                className="nav-item nav-link"
                                href="#"
                                onClick={logoutAsync}
                                title="Logout"
                            >
                                <FontAwesomeIcon
                                    icon={faSignOutAlt}
                                    size="lg"
                                />
                                <span className="nav-link-text">Logout</span>
                            </a>
                        </li>
                    ) : (
                        <li className="ms-auto">
                            <Link
                                onClick={() => setIsOpen(false)}
                                className={`nav-item ${navLinkCssClass([
                                    {path: 'login'}
                                ])}`}
                                to="/login"
                                title="Login"
                            >
                                <FontAwesomeIcon icon={faSignInAlt} size="lg" />
                                <span className="nav-link-text">
                                    Login / sign-up
                                </span>
                            </Link>
                        </li>
                    )}
                </>
            )}
        </ul>
    );
};

export default Menu;
