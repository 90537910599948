import React, {useState} from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    FormText
} from 'reactstrap';
import {useMutation} from 'react-query';
import useAddPendingGroupMember, {
    AddPendingGroupMemberCommand
} from '../../api/groups/useAddPendingGroupMember';
import {toast} from 'react-toastify';
import {MembershipStatus, UserCollectionType} from '../../api/types.ts';
import unknownErrorToString from '../../lib/unknownErrorToString.ts';
import ButtonAsync from '../shared/ButtonAsync.tsx';

interface RequestToJoinModalProps {
    dismiss: () => void;
    requested: (membershipStatus: MembershipStatus) => void;
    collectionId: string;
    nicknameOrEmailOrUserId: string;
}

const RequestToJoinModal: React.FC<RequestToJoinModalProps> = ({
    dismiss,
    requested,
    collectionId,
    nicknameOrEmailOrUserId
}) => {
    const [collectionJoinCode, setCollectionJoinCode] = useState('');
    const addPendingGroupMember = useAddPendingGroupMember();
    const mutation = useMutation(addPendingGroupMember, {
        onSuccess: data => {
            data && toast.success(data.message);
            data && requested(data.membershipStatus);
            dismiss();
        },
        onError: (error: unknown) => {
            toast.error(unknownErrorToString(error));
        }
    });

    const onRequestedAsync = async () => {
        const command: AddPendingGroupMemberCommand = {
            collectionId,
            groupAdminId: null,
            nicknameOrEmailOrUserId,
            userCollectionType: UserCollectionType.Contributor,
            membershipStatus: MembershipStatus.Requested,
            collectionJoinCode
        };

        await mutation.mutateAsync(command);
    };

    return (
        <Modal isOpen={true} toggle={dismiss}>
            <ModalHeader toggle={dismiss}>Request group membership</ModalHeader>
            <ModalBody>
                <div className="row mb-3">
                    <label>Invite code</label>
                    <Input
                        value={collectionJoinCode}
                        onChange={e => setCollectionJoinCode(e.target.value)}
                    />
                    <FormText color="muted">
                        Enter an invite code if you've been given one or leave
                        empty.
                    </FormText>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="mb-3">
                    <ButtonAsync
                        color="primary"
                        className="float-end ms-3"
                        onClick={onRequestedAsync}
                    >
                        Join
                    </ButtonAsync>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default RequestToJoinModal;
