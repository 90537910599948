import {useAuthStateManager} from '../../hooks/useAuthStateManager.tsx';
import React, {useEffect, useState} from 'react';
import useGetFiles from '../../api/uploads/useGetFiles.ts';
import {useQuery} from 'react-query';
import {
    CollectionModel,
    DirectoryModel,
    UserCollectionType
} from '../../api/types.ts';
import useGetUserCollections from '../../api/collections/useGetUserCollections.ts';
import {Spinner} from 'reactstrap';
import RootDirectoryNames from './RootDirectoryNames.ts';
import filterDirectory from './filterDirectory.ts';
import SelectCollection from './SelectCollection.tsx';
import AssetSearch from './AssetSearch.tsx';
import SpinnerIfLoading from '../shared/SpinnerIfLoading.tsx';
import ContainerDirectory from './ContainerDirectory.tsx';
import humanFileSize from '../../lib/humanFileSize.ts';
import Containers from '../../lib/Containers.ts';
import RoleNames from '../../lib/RoleNames.ts';

interface AssetsProps {
    containerName: string;
    showSelectCollection: boolean;
}

const Assets: React.FC<AssetsProps> = ({
    containerName,
    showSelectCollection = true
}) => {
    const [selectedContainerName, setSelectedContainerName] =
        useState(containerName);

    const [canContribute, setCanContribute] = useState(false);
    const [canUpload, setCanUpload] = useState(false);

    const [editingPath, setEditingPath] = useState<string | null>(null);

    const {loggedInUserId, authData, isInRole} = useAuthStateManager();

    const uploadLimit = authData.upload_limit;

    const fetchFiles = useGetFiles({
        containerName: selectedContainerName,
        folderPath: null
    });

    const {
        data: fetchedRootDirectory,
        isLoading,
        isError,
        refetch
    } = useQuery(['files', selectedContainerName], fetchFiles);

    const [rootDirectory, setRootDirectory] = useState<DirectoryModel | null>(
        null
    );

    useEffect(() => {
        if (fetchedRootDirectory) {
            setRootDirectory({...fetchedRootDirectory});
        }
    }, [fetchedRootDirectory]);

    const [searchText, setSearchText] = useState('');

    const [userCollections, setUserCollections] = useState<CollectionModel[]>(
        []
    );

    const fetchUserCollections = useGetUserCollections();

    const {data: userCollectionsData} = useQuery(
        ['userCollections', loggedInUserId],
        fetchUserCollections,
        {
            enabled: containerName != Containers.Binaries
        }
    );

    useEffect(() => {
        if (userCollectionsData) {
            setUserCollections(userCollectionsData);
        }
    }, [userCollectionsData]);

    useEffect(() => {
        if (containerName == Containers.Binaries) {
            const isAdmin = isInRole(RoleNames.Admin);
            setCanContribute(isAdmin);
            setCanUpload(isAdmin);
        } else if (userCollections && rootDirectory) {
            const selectedCollection = userCollections.find(
                c => c.containerName === containerName
            );

            if (selectedCollection) {
                const _canContribute =
                    selectedCollection.loggedInUserUserCollectionType !==
                    UserCollectionType.Viewer;

                setCanContribute(_canContribute);

                const _canUpload =
                    rootDirectory.name === RootDirectoryNames.Root &&
                    _canContribute &&
                    (uploadLimit === null ||
                        uploadLimit === 0 ||
                        uploadLimit === undefined ||
                        rootDirectory.length < uploadLimit);

                setCanUpload(_canUpload);
            } else {
                setCanContribute(false);
                setCanUpload(false);
            }
        }
    }, [containerName, userCollections, rootDirectory]);

    const onCollectionSelected = (containerName: string) =>
        setSelectedContainerName(containerName);

    if (isLoading) {
        return (
            <div className="spinner-full">
                <Spinner color="primary" />
            </div>
        );
    }

    if (isError || !rootDirectory) {
        return <p>Error fetching assets.</p>;
    }

    if (rootDirectory.name == RootDirectoryNames.StorageError) {
        return (
            <p className="text-danger">
                Storage error - container name: {containerName}
            </p>
        );
    }

    const filteredDirectory = filterDirectory(
        rootDirectory,
        ['*'],
        searchText
    ) ?? {
        ...rootDirectory,
        files: [],
        directories: []
    };

    return (
        <div>
            <div className="d-flex position-relative mb-2">
                {showSelectCollection && (
                    <SelectCollection
                        collections={userCollections}
                        containerName={selectedContainerName}
                        onCollectionSelected={onCollectionSelected}
                    />
                )}
                <AssetSearch
                    searchText={searchText}
                    setSearchText={setSearchText}
                />
            </div>

            <SpinnerIfLoading loading={rootDirectory === null}>
                {rootDirectory.name === RootDirectoryNames.StorageError ? (
                    <p className="text-danger">
                        Storage error - container name: {selectedContainerName}
                    </p>
                ) : (
                    <div className="uploads">
                        <ContainerDirectory
                            directory={filteredDirectory}
                            setDirectory={dir => {
                                setRootDirectory({...rootDirectory, ...dir});
                            }}
                            containerName={selectedContainerName}
                            onFilesUploaded={() => refetch()}
                            canContribute={canContribute}
                            canUpload={canUpload}
                            refetch={refetch}
                            editingPath={editingPath}
                            setEditingPath={setEditingPath}
                        />
                        <hr />
                        <div>
                            <small>
                                Total file size:{' '}
                                {humanFileSize(rootDirectory.length)}
                            </small>
                        </div>
                        <div>
                            <small>
                                Upload limit:{' '}
                                {humanFileSize(uploadLimit, 'Unlimited')}
                            </small>
                        </div>
                    </div>
                )}
            </SpinnerIfLoading>
        </div>
    );
};

export default Assets;
