import React, {useCallback, useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {Spinner} from 'reactstrap';
import CodeBlock from '../shared/CodeBlock.tsx';
import {FaCopy} from 'react-icons/fa';
import ReactDOMServer from 'react-dom/server';

interface MarkdownViewerProps {
    src: string;
}

const MarkdownViewer: React.FC<MarkdownViewerProps> = ({src}) => {
    const [copyState, setCopyState] = useState<{
        copied: boolean;
        text: string;
        className: string;
    }>({
        copied: false,
        text: 'Copy',
        className: ''
    });

    const {data: content, isLoading} = useQuery(['markdown', src], () =>
        fetch(src).then(res => res.text())
    );

    const renderMarkdown = useCallback(
        (
            markdownContent: string,
            context: 'display' | 'clipboard' = 'display'
        ) => (
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    code({node, className, children, ...props}) {
                        const match = /language-(\w+)/.exec(className || '');
                        const language = match ? match[1] : null;
                        if (context === 'clipboard') {
                            // For clipboard, we just return the plain text content
                            return <code>{children}</code>;
                        }
                        return language ? (
                            <CodeBlock
                                language={language}
                                value={String(children).replace(/\n$/, '')}
                                {...props}
                            />
                        ) : (
                            <code className={className} {...props}>
                                {children}
                            </code>
                        );
                    },
                    a({href, children, ...props}) {
                        const isExternal =
                            href &&
                            !href.startsWith('/') &&
                            !href.startsWith('#');
                        if (context === 'clipboard') {
                            // For clipboard, we return the link with its href
                            return `${children} (${href})`;
                        }
                        return (
                            <a
                                href={href}
                                target={isExternal ? '_blank' : undefined}
                                rel={
                                    isExternal
                                        ? 'noopener noreferrer'
                                        : undefined
                                }
                                {...props}
                            >
                                {children}
                            </a>
                        );
                    }
                }}
            >
                {markdownContent}
            </ReactMarkdown>
        ),
        []
    );

    const getTextContent = useCallback(() => {
        return content || '';
    }, [content]);

    const copyToClipboard = useCallback(() => {
        const markdownContent = getTextContent();
        const htmlContent = ReactDOMServer.renderToString(
            renderMarkdown(markdownContent, 'clipboard')
        );

        if (navigator.clipboard && window.ClipboardItem) {
            const clipboardItem = new ClipboardItem({
                'text/plain': new Blob([markdownContent], {type: 'text/plain'}),
                'text/html': new Blob([htmlContent], {type: 'text/html'})
            });

            navigator.clipboard
                .write([clipboardItem])
                .then(() => {
                    setCopyState({
                        copied: true,
                        text: 'Copied!',
                        className: 'text-success'
                    });
                })
                .catch(err => {
                    console.error('Failed to copy: ', err);
                    setCopyState({
                        copied: false,
                        text: 'Failed to copy',
                        className: 'text-danger'
                    });
                });
        } else {
            // Fallback for browsers that don't support the Clipboard API
            const textArea = document.createElement('textarea');
            textArea.value = markdownContent;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                setCopyState({
                    copied: true,
                    text: 'Copied!',
                    className: 'text-success'
                });
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
                setCopyState({
                    copied: false,
                    text: 'Failed to copy',
                    className: 'text-danger'
                });
            }
            document.body.removeChild(textArea);
        }
    }, [getTextContent, renderMarkdown]);

    useEffect(() => {
        let timer: number;
        if (copyState.copied) {
            timer = setTimeout(() => {
                setCopyState({copied: false, text: 'Copy', className: ''});
            }, 2000);
        }
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [copyState.copied]);

    if (isLoading) {
        return <Spinner />;
    }

    return content ? (
        <>
            {renderMarkdown(content, 'display')}
            <div className="d-flex justify-content-end">
                <button
                    onClick={copyToClipboard}
                    className={`btn btn-link p-0 ${copyState.className}`}
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        textDecoration: 'none'
                    }}
                >
                    {copyState.text} <FaCopy size={20} />
                </button>
            </div>
        </>
    ) : null;
};

export default MarkdownViewer;
